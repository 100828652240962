export function handleErrorFromRequest(error) {
  throw new Error(buildMessageFromRequestError(error));
}

export function buildErrorFromRequest(error) {
  return buildMessageFromRequestError(error);
}

function buildMessageFromRequestError(error) {
  let message = error?.response?.data?.errors?.join('.\n') || error?.response?.data?.error || null;
  if (message === null) {
    switch (error?.response?.status) {
      case 422:
        message = 'Nous ne pouvons traiter les données transmises.(Erreur 422)';
        break;

      case 404:
        message = 'Donnée introuvable.(Erreur 404)';
        break;

      case 500:
        message = 'Une erreur est survenue. Veuillez essayer à nouveau, ou contactez-nous.(Erreur 500)';
        break;

      case 401:
        message = "Vous n'êtes pas autorisé à faire cette action.(Erreur 401)";
        break;

      default:
        message = `Une erreur est survenue: ${error.message}`;
        break;
    }
  }
  return message;
}
